import { createRouter, createWebHistory } from 'vue-router'

const routes =  [
    {
      path: '/',
      name: 'home',
      component: () => import(/*webpackChunkName: "Home"*/'../views/HomeView.vue')
    },    
    {
      path: '/:pathMatch(.*)*',
      //name: 'register',
      component: () => import(/*webpackChunkName: "Home"*/'../views/HomeView.vue')
    }
  ]

  const router = createRouter({
    history: createWebHistory(),
    routes,
  })

export default router
